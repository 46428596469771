<template>
  <div class="CliConfigApp">
    <v-card-title>Personalização do app do Efetivo</v-card-title>
    <v-card-text>
      <DataTable :items="configs" :headers="headers">
        <!-- itens do menu -->
        <template slot="item.menus" slot-scope="{ item }">
          <v-chip
            v-for="(menu, index) in item.menus"
            :key="index"
            class="ma-1"
            small
          >
            <b> {{ menu.id }} - {{ menu.name }} </b>
          </v-chip>
        </template>

        <template slot="item.dispositivoId" slot-scope="{ item }">
          {{ getDeviceNameById(item.dispositivoId) }}
        </template>

        <template slot="item.nivelAcessoId" slot-scope="{ item }">
          {{ getLevelNameById(item.nivelAcessoId) }}
        </template>

        <template slot="item.groupId" slot-scope="{ item }">
          {{ $store.getters.getGroupNameById(item.groupId) }}
        </template>
        <!-- ações -->
        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editar(item)"
                color="warning"
                class="mr-1"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar : {{ getDeviceNameById(item.dispositivoId) }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="excluir(item)"
                color="red"
                class="mr-1"
                >mdi-trash-can</v-icon
              >
            </template>
            <span>Excluir : {{ getDeviceNameById(item.dispositivoId) }}</span>
          </v-tooltip>
        </template>
      </DataTable>
    </v-card-text>

    <!-- dialog de cadastro -->
    <v-dialog v-model="dialogCadastro" persistent max-width="900" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2"
          >{{ dvcConfig.id ? "Editar" : "Cadastrar" }} configuração<v-spacer
          ></v-spacer>
          <v-btn icon @click="closeCadastro">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-2 pb-0">
          <v-form ref="formConfig">
            <!-- linha dvc e lvl -->
            <v-row>
              <v-col class="pb-1">
                <v-autocomplete
                  v-model="dvcConfig.dispositivoId"
                  :items="dispositivos"
                  label="Dispositivo"
                  outlined
                  item-text="name"
                  item-value="id"
                  :rules="requiredField"
                ></v-autocomplete>
              </v-col>
              <v-col class="pb-1">
                <v-autocomplete
                  v-model="dvcConfig.nivelAcessoId"
                  :items="niveis"
                  label="Nível de acesso"
                  outlined
                  item-text="name"
                  item-value="id"
                  :rules="requiredField"
                  :disabled="!dvcConfig.dispositivoId"
                  @change="getRoutes"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-card outlined class="mb-8">
              <v-card-subtitle>
                <span>Páginas disponíveis para o dispositivo</span>
              </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card outlined height="100%">
                      <v-card-title>Disponíveis</v-card-title>
                      <v-card-subtitle>Clique para adicionar</v-card-subtitle>
                      <v-card-text>
                        <v-list two-line subheader>
                          <v-list-item
                            v-for="rt in routes"
                            :key="rt.id"
                            @click="addRoute(rt.id)"
                            v-show="isAvailable(rt.id)"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{
                                rt.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                rt.to
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card outlined height="100%">
                      <v-card-title>Ativas</v-card-title>
                      <v-card-subtitle>Clique para remover</v-card-subtitle>
                      <v-card-text>
                        <v-list two-line subheader class="pb-0">
                          <v-list-item
                            v-for="rt in dvcConfig.menus"
                            :key="rt.id"
                            @click="remRoute(rt.id)"
                          >
                            <!-- icone de mudança de posição -->
                            <v-list-item-icon class="mb-0 mr-4">
                              <v-btn icon @click.stop="changePos(rt.id, true)">
                                <v-icon>mdi-arrow-up</v-icon>
                              </v-btn>
                              <v-btn icon @click.stop="changePos(rt.id, false)">
                                <v-icon>mdi-arrow-down</v-icon>
                              </v-btn>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                rt.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                rt.to
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <!-- ações do card -->
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closeCadastro">Cancelar</v-btn>
          <v-btn color="success" @click="save()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          bottom
          right
          fab
          fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialogCadastro = true"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Configuração</span>
    </v-tooltip>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "CliConfigApp",
  components: {
    DataTable,
    ConfirmDialog,
  },
  props: ["configs"],
  data: () => {
    return {
      dialogCadastro: false,
      requiredField,
      dvcConfig: {
        dispositivoId: null,
        nivelAcessoId: null,
        menus: [],
      },
      devices: [],
      niveis: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Dispositivo", value: "dispositivoId" },
        { text: "Nivel de acesso", value: "nivelAcessoId" },
        { text: "Menus", value: "menus" },
        {
          text: "Ações",
          value: "actions",
          align: "center",
          sortable: false,
          filterable: false,
          width: 112,
        },
      ],
      actives: [],
      availables: [],
      routes: [],
    };
  },
  computed: {
    dispositivos() {
      return this.$store.getters.getDispositivos.filter((item) => item.enabled);
    },
  },
  methods: {
    reset() {
      this.dvcConfig = {
        dispositivoId: null,
        nivelAcessoId: null,
        menus: [],
      };
      this.routes = [];
    },
    save() {
      if (!this.$refs.formConfig.validate()) return;
      this.dvcConfig.clientId = this.$store.getters.getCliente.id;
      this.dvcConfig.groupId = this.$store.getters.getCliente.groupId;
      const metodo = this.dvcConfig.id ? "put" : "post";
      const finalUrl = this.dvcConfig.id ? `/${this.dvcConfig.id}` : "";
      this.$http[metodo]("dispositivo/config" + finalUrl, this.dvcConfig).then(
        (resp) => {
          if (resp.status < 300) {
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSuccessSnack", "Salvo com Sucesso");
              this.closeCadastro();
              this.$emit("refresh");
            }
        }
      );
    },
    editar(item) {
      this.dvcConfig = { ...item, dispositivoId: parseInt(item.dispositivoId) };
      this.dialogCadastro = true;
      this.getRoutes(item.nivelAcessoId);
    },
    excluir(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir Configuração",
        text: `Deseja excluir a configuração <strong>${this.getDeviceNameById(item.dispositivoId)}</strong>?`,
        confirmCallback: () => {
          this.$http.delete("dispositivo/config/" + item.id).then((resp) => {
            if (resp.status < 300) {
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSuccessSnack", "Deletado com Sucesso");
              this.$emit("refresh");
            }
          });
        },
      });
    },
    closeCadastro() {
      this.dialogCadastro = false;
      this.$refs.formConfig.resetValidation();
      this.reset();
    },
    getAcesso() {
      this.$http("nvacesso").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${element.description})`;
          element.value = element.id;
        });
        this.niveis = resp.data;
      });
    },
    getRoutes(nvAcessoId) {
      this.$http("nvmenuApp?nivel=" + nvAcessoId).then((resp) => {
        this.routes = resp.data;
      });
    },
    addRoute(id) {
      let rt = this.routes.find((r) => r.id == id);
      this.dvcConfig.menus.push(rt);
    },
    remRoute(id) {
      let rt = this.dvcConfig.menus.findIndex((r) => r.id == id);
      this.dvcConfig.menus.splice(rt, 1);
    },
    // verifica se ja esta na lista dos ativos
    isAvailable(id) {
      return this.dvcConfig.menus.findIndex((r) => r.id == id) == -1;
    },
    getDeviceNameById(id) {
      let a = this.dispositivos.find((d) => d.id == id);
      return a ? `${a.id} - ${a.name}` : "";
    },
    getLevelNameById(id) {
      let a = this.niveis.find((d) => d.id == id);
      return a ? `${a.id} - ${a.name}` : "";
    },
    // altera a posição do item no array
    changePos(id, up) {
      let rt = this.dvcConfig.menus.findIndex((r) => r.id == id);
      this.dvcConfig.menus.splice(rt, 1);
      if (up) {
        this.dvcConfig.menus.splice(
          rt - 1,
          0,
          this.routes.find((r) => r.id == id)
        );
      } else {
        this.dvcConfig.menus.splice(
          rt + 1,
          0,
          this.routes.find((r) => r.id == id)
        );
      }
    },
  },
  created() {
    this.reset();
    this.getAcesso();
    this.$store.dispatch("loadDispositivos", this.$store.getters.getCliente.groupId );
  },
};
</script>

<style>
</style>