<template>
  <div class="cliente">
    <ClienteCard :cliente="cliente" @refresh="refresh" />
    <v-card>
      <v-tabs fixed-tabs background-color="success" show-arrows class="rounded-b-0">
        <v-tab><v-icon class="mr-1">mdi-cog</v-icon>Configurações ({{ configs.length }})</v-tab>
        <v-tab-item>
          <CliConfigApp :configs="configs" @refresh="refresh" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ClienteCard from "./components/ClienteCard";
import CliConfigApp from "./components/CliConfigApp";

export default {
  name: "ClienteApp",
  components: {
    ClienteCard,
    CliConfigApp,
  },
  computed: {
    cliente() {
      return this.$store.getters.getCliente;
    },
  },
  data: () => {
    return {
      configs: [],
    };
  },
  methods: {
    refresh() {
      this.getConfigs();
    },
    getConfigs() {
      this.$http("dispositivo/config?groupId=" + this.cliente.groupId).then((resp) => {
        this.configs = resp.data;
      });
    },
  },
  created() {
    this.refresh();
  },
};
</script>